import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactDetails } from '../components/contact-details';
import { ContactForm } from '../components/contact-form';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { ButtonLink } from '../components/ui/button-link';
import { Wave } from '../icons/wave';

function ContactPage(): React.ReactElement {
  return (
    <>
      <SEO title="Contact Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/contact-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <OurContactDetails />
        <ContactFormSection />
      </Layout>
    </>
  );
}

function OurContactDetails(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Our
            <br />
            <span className="font-semibold">Contact Details</span>
          </h2>
          <br />
          <ContactDetails />
        </SideBySide.ThreeCols>
        <SideBySide.TwoCols>
          <StaticImage
            quality={100}
            src="../images/contact-one.png"
            alt=""
            className="w-[20rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

function ContactFormSection(): React.ReactElement {
  return (
    <div className="relative" id="contact">
      <Wave className="text-teal-light" />
      <div className="px-4 py-12 sm:px-6 lg:px-8 bg-teal-light">
        <div className="w-full max-w-screen-lg mx-auto">
          <h2 className="text-4xl text-center">
            Get in touch <br />
            <span className="font-semibold">Today</span>
          </h2>
          <div className="mt-8">
            <ContactForm submitAlignment="center" submitColor="brown" />
          </div>
        </div>
      </div>
      <div className="bg-teal-light">
        <Wave className="text-white" />
      </div>
    </div>
  );
}

export default ContactPage;
